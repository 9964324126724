export default {
  namespaced: true,
  state: {
    titleList: [],
  },
  getters: {
    getTitleList: (state) => () => {
      return state.titleList
    },
  },
  mutations: {
    SET_TITLE_LIST(state, data) {
      state.titleList = data
    },
  },
  actions: {
    async setTitleList({ commit }, data) {
      commit('SET_TITLE_LIST', data)
    },
  },
}
