export default {
  namespaced: true,
  state: {
    dataMode: false,
  },
  getters: {
    getDataMode: (state) => () => {
      return state.dataMode
    },
  },
  mutations: {
    SET_DATA_MODE(state, mode) {
      state.dataMode = mode
    },
  },
  actions: {
    async setFancyDataMode({ commit }) {
      commit('SET_DATA_MODE', true)
    },
    async setBriefDataMode({ commit }) {
      commit('SET_DATA_MODE', false)
    },
  },
}
