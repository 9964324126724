// import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    titles: [],
  },
  getters: {
    getTitles: (state) => () => {
      return state.titles;
    },
    getNcTitleId: (state) => (selected) => {
      if (!selected) {
        return null;
      }
      return state.titles.find((item) => item.id === selected.id);
    },
  },
  mutations: {
    CLEAR_TITLES(state) {
      state.titles = [];
    },
    SET_TITLES(state, data) {
      const result = [];
      // go through the list and set up a transposition id
      for (let x = 0; x < data.titleResult.length; x++) {
        result.push({
          id: result.length + 1,
          ncTitleId: data.titleResult[x].TableRowId,
          title: `Title • ${data.titleResult[x].SearchTerm}`,
          // type: data.titleResult[x].type,
          // year: data.titleResult[x].year,
        });
      }

      for (let x = 0; x < data.actorResult.length; x++) {
        result.push({
          id: result.length + 1,
          ncTitleId: data.actorResult[x].TableRowId,
          title: `Actor • ${data.actorResult[x].SearchTerm} • ${data.actorResult[x].title}`,
          year: data.actorResult[x].year,
        });
      }

      for (let x = 0; x < data.directorResult.length; x++) {
        result.push({
          id: result.length + 1,
          ncTitleId: data.directorResult[x].TableRowId,
          title: `Director • ${data.directorResult[x].SearchTerm} • ${data.directorResult[x].title}`,
          year: data.directorResult[x].year,
        });
      }
      state.titles = result;
    },
  },
  actions: {
    // async fetchTitle({ commit }, id) {
    //   let response = await dataApi.getTitle(id)
    //   if (response.data) {
    //     commit('SET_TITLES', response.data)
    //   }
    // },
    clearTitles({ commit }) {
      commit("CLEAR_TITLES");
    },
    setTitles({ commit }, data) {
      commit("SET_TITLES", data);
    },
  },
};
