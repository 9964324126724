<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dense class="pl-0">
        <v-btn text
          class="pl-0 pr-0"
          @click="onBackButton"
        >
          <v-icon>mdi-arrow-left</v-icon>Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-card-text class="d-sm-none pl-2" style="fontSize: x-small">{{title}}</v-card-text>
        <v-card-title class="d-none d-sm-block">{{title}}</v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card 
        class="d-flex flex-wrap offset-left-3"
        flat
      >
        <v-card-actions
          v-for="(item, index) in getItems" :key="index"
          class="pa-2 justify-center"
        >
          <title-card :item="item"></title-card>
        </v-card-actions>

      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
  import TitleCard from './TitleLists/TitleCards/TitleCard'
  export default {
    props: [],
    components: {
        TitleCard
    },  
    data () {
      return {
        dialog: true,
        title: ''
      }
    },
    methods: {
      onBackButton() {
        this.$router.back()
      }
    },
    computed: {
      getItems() {
        return this.$store.getters['bucket/getTitleList']()
      },
    },    
    watch: {
      async '$route.params.titleId'(newId) {
        await this.$store.dispatch('title/fetchTitle', newId)
        const bucket = this.$route.params.bucket
        let data = []
        switch (bucket) {
          case 'mostlikes': {
            data = this.$store.getters['title/getMostLikes']()
            break
          }
          case 'nanogenre': {
            const nanogenre = this.$store.getters['title/getNanogenre'](this.$route.params.bucketId)
            data = nanogenre?.nanogenreTitles
            break
          }
          case 'minigenre': {
            const minigenre = this.$store.getters['title/getMinigenre'](this.$route.params.bucketId)
            data = minigenre?.minigenreTitles
            break
          }
          case 'genre': {
            const genre = this.$store.getters['title/getGenre'](this.$route.params.bucketId)
            data = genre?.genreTitles
            break
          }
        }
        this.$store.dispatch('bucket/setTitleList', data)
      },
    },
    async mounted() {
      if (this.$route.params.titleId) {
        this.title = localStorage.getItem('toolbarTitle')
        // check the title store for the titleId
        const currentTitleId = this.$store.getters['title/getTitleId']()
        if (this.$route.params.titleId !== currentTitleId) {
          await this.$store.dispatch('title/fetchTitle', this.$route.params.titleId)
        }
        const bucket = this.$route.params.bucket
        let data = []
        switch (bucket) {
          case 'mostlikes': {
            data = this.$store.getters['title/getMostLikes']()
            break
          }
          case 'nanogenre': {
            const nanogenre = this.$store.getters['title/getNanogenre'](this.$route.params.bucketId)
            data = nanogenre?.nanogenreTitles
            break
          }
          case 'minigenre': {
            const minigenre = this.$store.getters['title/getMinigenre'](this.$route.params.bucketId)
            data = minigenre?.minigenreTitles
            break
          }
          case 'genre': {
            const genre = this.$store.getters['title/getGenre'](this.$route.params.bucketId)
            data = genre?.genreTitles
            break
          }
        }
        this.$store.dispatch('bucket/setTitleList', data)
      }
    }
  }
</script>
<style scoped>
.offset-left-3 {
  left: 3%;
}
</style>