<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      @click:outside="closeDialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-icon x-large>mdi-youtube</v-icon>
      </div>
      </template>

      <v-card>
        <!-- <v-card-title class="text-h5 grey lighten-2">
          Trailer
        </v-card-title> -->

        <iframe id="trailer-player" :src="getTrailerUrl" width='100%' height='350' frameborder='0 ' allowfullscreen></iframe>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

  export default {
    props: [],
    data: () => ({
      dialog: false,
      // ytsrc: 'https://www.youtube.com/embed/mlNwXuHUA8I'
    }),
    methods: {
      openDialog() {
        const trailerId = this.$store.getters['title/getTrailerId']()
        const url = `https://www.youtube.com/embed/${trailerId}`
        const playerElement = document.getElementById('trailer-player')
        if (playerElement) {
          playerElement.setAttribute('src', url)
        }
      },
      closeDialog() {
        this.dialog = false
        const playerElement = document.getElementById('trailer-player')
        if (playerElement) {
          playerElement.setAttribute('src', '')
        }
      }
    },
    computed: {
      getTrailerUrl() {
        const trailerId = this.$store.getters['title/getTrailerId']()
        const url = `https://www.youtube.com/embed/${trailerId}`
        return url
      }
    },
    watch: {
      dialog(visible) {
        if (visible) {
          this.openDialog()
        } else {
          this.closeDialog()
        }
      }
    }
  }
</script>
<style scoped>

</style>