<template>
  <v-container fluid class="pt-0">
    <v-card class="tagcloud" color="#333" max-width="250" flat>
      <v-card-text>
        <span v-for="(item, index) in this.reorderList" class="word" :class="getWordSize(index)" :key="index">{{item.keyword}} </span>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  export default {
    props: ['keywords'],
    data: () => ({
      sortedKeywordList: [],
    }),
    methods: {
      getWordSize(index) {
        switch(index) {
          case 0:
            return 'size6'
          case 1:
            return 'size17'
          case 2:
            return 'size7'
          case 3:
            return 'size16'
          case 4:
            return 'size12'
          case 5:
            return 'size6'
          case 6:
            return 'size18'
          case 7:
            return 'size6'
          case 8:
            return 'size17'
          case 9:
            return 'size6'
          case 10:
            return 'size10'
          case 11:
            return 'size6'
          case 12:
            return 'size7'
          case 13:
            return 'size12'
          case 14:
            return 'size7'
          default:
            return 'size6'
        }
      },
    },
    computed: {
      reorderList() {
        return [
          this.keywords[11], this.keywords[1], this.keywords[8], this.keywords[3], this.keywords[4],
          this.keywords[13], this.keywords[0], this.keywords[14], this.keywords[2], this.keywords[12],
          this.keywords[6], this.keywords[10], this.keywords[7], this.keywords[5], this.keywords[9],
        ]
      }
    },
    mounted() {
    }
  }
</script>
<style scoped>
  .tagcloud {
      /*width: 38%;*/
      border: #cccccc 1px solid;
      border-radius: 7px;
      -moz-border-radius: 7px;
      -webkit-border-radius: 7px;
      padding: 9px;
      /* margin-right: 2px; */
      background-color: #fff;
      text-align: center;
      float: left;
      /*margin-top: 10px;*/
      margin-left: 5px;
  }

  /************************************************************\
  *
  *	  wordCloud Copyright 2007 Derek Harvey
  *	  www.lotsofcode.com
  *
  *	  This file is part of wordCloud.
  *
  *	  wordCloud is free software; you can redistribute it and/or modify
  *	  it under the terms of the GNU General Public License as published by
  *	  the Free Software Foundation; either version 2 of the License, or
  *	  (at your option) any later version.
  *
  *	  wordCloud is distributed in the hope that it will be useful,
  *	  but WITHOUT ANY WARRANTY; without even the implied warranty of
  *	  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.	See the
  *	  GNU General Public License for more details.
  *
  *	  You should have received a copy of the GNU General Public License
  *	  along with wordCloud; if not, write to the Free Software
  *	  Foundation, Inc., 59 Temple Place, Suite 330, Boston, MA	02111-1307	USA
  *
  \************************************************************/

  .word {
    font-family: 'trebuchet ms', 'century gothic', verdana, sans-serif;
    padding: 0px 0px 0px 0px;
    letter-spacing: 0px;
  }
  span.size18 {
    color: #fff;
    font-size: 160%;
  }
  span.size17 {
    color: #fff;
    font-size: 155%;
  }
  span.size16 {
    color: #fff;
    font-size: 150%;
  }
  span.size15 {
    color: #fff;
    font-size: 145%;
  }
  span.size14 {
    color: #eee;
    font-size: 140%;
  }
  span.size13 {
    color: #ddd;
    font-size: 135%;
  }
  span.size12 {
    color: #ccc;
    font-size: 130%;
  }
  span.size11 {
    color: #bbb;
    font-size: 125%;
  }
  span.size10 {
    color: #aaa;
    font-size: 120%;
  }
  span.size9 {
    color: #999;
    font-size: 115%;
  }
  span.size8 {
    color: #999;
    font-size: 110%;
  }
  span.size7 {
    color: #888;
    font-size: 105%;
  }
  span.size6 {
    color: #777;
    font-size: 100%;
      /*font-size: 180%;*/
  }
</style>