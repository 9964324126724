<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="titles"
      :items-per-page="15"
      item-key="ncTitleId"
      class="elevation-5"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Most Like</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.imdbId`]="{ item }">
        <a
          target="_blank"
          :href="item.imdbLink"
          style="text-decoration: none"
          >{{ item.imdbId }}</a
        >
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <div @click="onTitleClicked(item.ncTitleId)" class="hand-cursor">{{ item.title }}</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ['title'],
  data: () => ({
    headers: [
      { text: 'titleID', sortable: false, value: 'ncTitleId' },
      { text: 'ttID', sortable: false, value: 'imdbId' },
      { text: 'Title', sortable: false, value: 'title' },
      { text: 'Year', sortable: true, value: 'year' },
      { text: 'Type', sortable: true, value: 'type' },
      { text: 'Crowd Rating', sortable: true, value: 'rating'},
      { text: 'Crowd Size', sortable: true, value: 'size'},
      { text: 'Distance', value: 'distance' },
    ]
  }),
  methods: {
    onTitleClicked(ncTitleId) {
      // this.$router.push(`/title/${ncTitleId}`)
      this.$router.safePush(`/title/${ncTitleId}`)
    }
  },
  computed: {
    titles() {
      const result = []
      const mostLikeTitles = this.$store.getters['title/getMostLikes']()

      for (let x = 0; x < mostLikeTitles.length; x++) {
        result.push({
          distance: parseFloat(mostLikeTitles[x].distance).toFixed(1),
          ncTitleId: mostLikeTitles[x].ncTitleId,
          title: mostLikeTitles[x].title,
          type: mostLikeTitles[x].type,
          rating: mostLikeTitles[x].crowdRating.toFixed(1),
          size: mostLikeTitles[x].crowdSize.toFixed(1),
          year: mostLikeTitles[x].year,
          imdbId: mostLikeTitles[x].imdbId,
          imdbLink: `https://www.imdb.com/title/${mostLikeTitles[x].imdbId}`
        })
      }

      return result
    }
  }
}
</script>
<style scoped>
.hand-cursor {
  cursor: pointer;
  color: #1976d2;
}
</style>
