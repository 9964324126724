<template>
  <span>
    <v-card flat v-for="(minigenre, index) in getMinigenreTitles" :key="index" class="pb-3">
      <v-toolbar short dense flat>
        <v-card-text class="pb-0 pl-0 pr-0 d-sm-none" style="fontSize: x-small">{{minigenre.label}}</v-card-text>
        <v-card-title class="pb-0 d-none d-sm-block">{{minigenre.label}}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="onExpandAllClick(minigenre)"><v-icon>mdi-arrow-expand-all</v-icon></v-btn>
      </v-toolbar>
      <title-collection :items="minigenre.titleList"></title-collection>
      <v-divider class="mt-2"></v-divider>
    </v-card>
  </span>
</template>

<script>
import TitleCollection from './TitleLists/TitleCollection'
export default {
  props: [],
  components: {
    TitleCollection,
  },
  methods: {
    onExpandAllClick(minigenre) {
      localStorage.setItem('appTab', 'minigenres')
      localStorage.setItem('toolbarTitle', minigenre.label)
      this.$router.safePush(`/bucket/${this.$store.getters['title/getTitleId']()}/minigenre/${minigenre.minigenreId}`)
    }
  },  
  computed: {
    getMinigenreTitles() {
      const title = this.$store.getters['title/getMinigenres']()
      if (title) {
        const minigenreIdList = title.map(item => item.minigenreId)
        const labels = title.map(item => item.label)
        const minigenreTitleList = title.map(item => item.minigenreTitles).map(item => item)
        const result = [];
        for (let x = 0; x < labels.length; x++) {
          result.push({minigenreId: minigenreIdList[x], label: labels[x], titleList: minigenreTitleList[x]})
        }

        return result
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>