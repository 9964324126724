import Vue from 'vue'
import Vuex from 'vuex'
import catalogSearch from './modules/catalogSearch.store'
import title from './modules/title.store'
import dataMode from './modules/dataMode.store'
import darkMode from './modules/darkMode.store'
import bucket from './modules/bucket.store'
import auth from './modules/auth.store'
import nanocrowdDefinitions from './modules/nanocrowdDefinitions.store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    catalogSearch,
    title,
    dataMode,
    darkMode,
    bucket,
    auth,
    nanocrowdDefinitions
  },
})
