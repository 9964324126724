<template>
  <v-autocomplete
    flat
    dense
    solo-inverted
    v-model="selectedTitle"
    :loading="isLoading"
    :items="allTitles"
    :search-input.sync="search"
    item-text="title"
    item-value="id"
    label="Titles, Actors, Directors"
    placeholder="Start typing to search"
    class="search-padding-30"
    hide-no-data
    @change="getSelection"
    return-object
  ></v-autocomplete>
</template>

<script>
import dataApi from '../services/dataApi'

export default {
  data: () => ({
    selectedTitle: '',
    entries: [],
    isLoading: false,
    search: null,
  }),
  computed: {
    allTitles() {
      const titles = this.$store.getters['catalogSearch/getTitles']()

      const searchList = titles.map((item) => {
        return {
          id: item.id,
          title: `${item.title} ${item.year ? '(' + item.year + ')' : ''}`,
        }
      })

      return searchList
      // return []
    },
  },
  methods: {
    async getSelection(value) {
      // get the transposed value of the ncTitleId
      const title = this.$store.getters['catalogSearch/getNcTitleId'](value)

      if (title) {
        // this.$router.push(`/title/${title.ncTitleId}`)
        this.$router.safePush(`/title/${title.ncTitleId}`)
      }

      this.$nextTick(() => {
          this.selectedTitle = null
          this.entries = []
      })
    },
    clearEntries() {
      this.count = 0
      this.entries = []
    },
    fetchEntriesDebounced() {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.fetchEntries()
      }, 10) /* 500ms throttle */
    },
    async fetchEntries() {
      let response = await dataApi.search(this.val)
      this.isLoading = false
      if (response) {
        this.$store.dispatch('catalogSearch/setTitles', response.data)
      }
    }
  },
  watch: {
    search(val) {
      this.$store.dispatch('catalogSearch/clearTitles')
      if (!val) {
        return
      }
      this.val = val
      this.clearEntries()
      this.isLoading = 'error'
      this.fetchEntriesDebounced()
    }
  }
}
</script>
<style scoped>
.search-padding-30 {
  padding-top: 30px !important;
}
</style>
