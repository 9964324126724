<template>
  <v-data-table
    dense
    show-expand
    :headers="headers"
    :items="nanogenres"
    :items-per-page="-1"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    :footer-props="{
      'items-per-page-options': [1, 2, 3, 5, -1]
    }"
    item-key="nanogenreId"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Nanogenre</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          v-model="singleExpand"
          label="Single expand"
          class="mt-2"
        ></v-switch>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length">
        <table class="nanogenre-titles">
          <thead>
            <tr>
              <th class="pl-2 text-start">IMDb Id</th>
              <th class="pl-2 text-start">Title</th>
              <th class="pl-2 text-start">Year</th>
              <th class="pl-2 text-start">Type</th>
              <th class="pl-2 text-start">Crowd Rating</th>
              <th class="pl-2 text-start">Crowd Size</th>
            </tr>
          </thead>
          <tr v-for="el in item.nanogenreTitles" :key="el.imdbId">
            <td>
              <a
                target="_blank"
                :href="el.imdbLink"
                style="text-decoration: none"
                >{{ el.imdbId }}</a
              >
            </td>
            <td>
              <div @click="onTitleClicked(el.ncTitleId)" class="hand-cursor">
                {{ el.title }}
              </div>
            </td>
            <td>{{ el.year }}</td>
            <td>{{ el.type }}</td>
            <td>{{ el.crowdRating }}</td>
            <td>{{ el.crowdSize }}</td>
          </tr>
        </table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    expanded: [],
    singleExpand: true,
    headers: [
      { text: 'UI Sort', sortable: true, value: 'rank' },
      {
        text: 'Distance',
        sortable: true,
        align: 'start',
        value: 'score'
      },
      { text: 'ngID', sortable: false, value: 'nanogenreId' },
      { text: 'Name', sortable: false, value: 'nanogenreName' },
      {
        text: 'Nanogenre Title keywords',
        sortable: false,
        value: 'nanogenreKeywords'
      },
      { text: '', value: 'data-table-expand' }
    ]
  }),
  methods: {
    onTitleClicked(ncTitleId) {
      this.$router.safePush(`/title/${ncTitleId}`)
    }
  },
  computed: {
    nanogenres() {
      const titleNanogenres = this.$store.state.title.title.titleNanogenreList
      if (titleNanogenres) {
        const nanogenreList = titleNanogenres.map(
          (item, index) => {
            item.nanogenreTitles.map((el) => {
              el.imdbLink = `https://www.imdb.com/title/${el.imdbId}`
            })
            return {
              rank: index + 1,
              nanogenreName: item.nanogenreWordList[0].join(' • '),
              score: parseFloat(item.distance).toFixed(1),
              nanogenreId: item.nanogenreId,
              nanogenreKeywords: item.nanogenreKeywords
                .map((el) => el.keyword)
                .join(' • '),
              nanogenreTitles: item.nanogenreTitles
            }
          }
        )
        return nanogenreList
      } else {
        return []
      }
    }
  }
}
</script>
<style scoped>
.nanogenre-titles {
  position: relative;
  margin: 10px auto;
  padding: 0;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  text-align: start;
}

.nanogenre-titles tr {
  margin: 0;
  padding: 0;
  border: 0;
  border: 1px solid #999;
  width: 100%;
}

.nanogenre-titles tr td {
  margin: 0;
  padding: 4px 8px;
  border: 0;
  border: 1px solid #999;
}

.hand-cursor {
  cursor: pointer;
  color: #1976d2;
}
</style>
