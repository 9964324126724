<template>
  <div>
    <mostlikes-data title="Most Like Titles"></mostlikes-data>
    <nanogenres-data></nanogenres-data>
    <minigenres-data></minigenres-data>
    <genres-data></genres-data>
  </div>
</template>

<script>
import MostlikesData from './MostLikes/MostLikesData'
import NanogenresData from './Nanogenres/NanogenresData'
import GenresData from './Genres/GenresData'
import MinigenresData from './Minigenres/MinigenresData'

export default {
  components: { 
    MostlikesData,
    NanogenresData, 
    MinigenresData,
    GenresData, 
  },
  data: () => ({}),
  methods: {},
  computed: {}
}
</script>
<style scoped></style>
