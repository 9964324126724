<template>
  <nanogenres-data></nanogenres-data>
</template>

<script>
import NanogenresData from './NanogenresData'
export default {
  components: { NanogenresData },
}
</script>
<style scoped></style>
