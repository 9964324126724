<template>
  <span>
    <v-container fluid>
      <v-row>
        <div class="pt-4 pl-4 pr-4 pb-4">
          <v-card class="d-flex flex-column" min-height="200" flat outlined color="">
            <v-card flat min-height="200" outlined>
              <v-card-title class="pt-1 pb-0 nanogenre-title-color">Nanogenre (closest 6)</v-card-title>
              <v-list class="transparent">
                <v-list-item id="nanogenre-summary" class="item-height" v-for="(item, index) in getNanogenres" :key="index">
                  <v-list-item-title>{{ item.nanogenre }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-card>
        </div>
        <div class="pt-4 pl-4 pr-4 pb-4">
          <v-card class="d-flex flex-column" min-height="200" flat outlined color="">
            <v-card flat min-height="200" outlined>
              <v-card-title class="pt-1 pb-0 minigenre-title-color">Minigenre</v-card-title>
              <v-list class="transparent">
                <v-list-item id="minigenre-summary" class="item-height minigenres" v-for="(item, index) in getMinigenres" :key="index">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-card>
        </div>
        <div class="pt-4 pl-4 pr-4 pb-4">
          <v-card class="d-flex flex-column" min-height="200" flat outlined color="">
            <v-card flat min-height="200" outlined>
              <v-card-title class="pt-1 pb-0 genre-title-color">Genre</v-card-title>
              <v-list class="transparent">
                <v-list-item id="genre-summary" class="item-height genres" v-for="(item, index) in getGenres" :key="index">
                  <v-list-item-title>{{ item.genre }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-card>
        </div>
      </v-row>
    </v-container>
    <v-card flat>
      <v-toolbar dense flat>
        <v-card-title class="pb-0">Most Like Titles</v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="onExpandAllClick()"><v-icon>mdi-arrow-expand-all</v-icon></v-btn>
      </v-toolbar>
    </v-card>
    <title-collection :items="getMostLikeTitles"></title-collection>
    <v-divider class="mt-4"></v-divider>
  </span>
</template>

<script>
import TitleCollection from './TitleLists/TitleCollection'

export default {
  props: [],
  components: {
    TitleCollection
  },  
  methods: {
    onExpandAllClick() {
      localStorage.setItem('appTab', 'summary')
      localStorage.setItem('toolbarTitle', 'Title Summary')
      this.$router.safePush(`/bucket/${this.$store.getters['title/getTitleId']()}/mostlikes`)
    }
  },  
  computed: {
    getMostLikeTitles() {
      const mostLikes = this.$store.getters['title/getMostLikes']()
      if (mostLikes) {
        const titles = mostLikes.map((item) => {
          return { 
            ncTitleId: item.ncTitleId, 
            title: item.title, 
            year: item.year,
            crowdRating: item.crowdRating,
            crowdSize: item.crowdSize,
          }
        })
        return titles
      }
      return []
    },
    getNanogenres() {
      function sortData(a, b) {
        if (a.distance < b.distance) {
          return -1
        }
        if (a.distance > b.distance) {
          return 1
        }
        return 0
      }
      const data = [...this.$store.getters['title/getNanogenres']()]
      if (data) {
        const sortedData = data.sort(sortData)
        const nanogenres = sortedData.map((item) => {
          return { 
            nanogenre: item.nanogenreWordList[0].join(' • ')
          }
        })
        return nanogenres.slice(0, 6)
      }
      return []
    },
    getMinigenres() {
      const title = this.$store.getters['title/getMinigenres']()
      if (title) {
        return title.map(item => item.label)
      }

      return []
    },
    getGenres() {
      const data = this.$store.getters['title/getGenres']()
      if (data) {
        const genres = data.map((item) => {
          return { 
            genre: item.label
          }
        })
        return genres.slice(0, 6)
      }
      return []
    }
  }
}
</script>

<style scoped>
  .item-height {
    min-height: 26px !important;
  }
  /* #098FE0 */
  div.v-list div#nanogenre-summary.v-list-item:nth-of-type(odd) {
   background-color: rgba(9, 143, 224, 1);
 }
 /* #50AFE8 */
 div.v-list div#minigenre-summary.v-list-item:nth-of-type(odd) {
   background-color: rgba(80, 175, 232, 1);
 }
 div.v-list div#genre-summary.v-list-item:nth-of-type(odd) {
   background-color: rgba(42, 168, 244, 1);
 }

 .nanogenre-title-color {
  color: #098FE0;
 }
 .minigenre-title-color {
  color: #50AFE8;
 }

 .genre-title-color {
  color: #2AA8F4;
 }

</style>
