<template>
  <v-app>
    <!-- <v-app-bar
      app
      color="primary"
      dark
    >
    </v-app-bar> -->

    <v-main>
      <!-- <home-page/> -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
  async created() {
  },
  async mounted() { 
    this.$store.dispatch('nanocrowdDefinitions/getDataDefinitions')
    const darkMode = localStorage.getItem('darkMode')
    if (darkMode) {
      this.$vuetify.theme.dark = true
      this.$store.dispatch('darkMode/setDarkMode')
    }
  }
};
</script>
<style>
body {
  background-color: #272727;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}

.nc-item {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
  background-color: gray !important;
}

</style>