import Vue from 'vue'
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

import firebaseConfig from './firebaseConfig'

import App from './App.vue'
import store from './store'
import router from './router'


import vuetify from './plugins/vuetify'

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)

Vue.config.productionTip = false

// eslint-disable-next-line no-unused-vars
onAuthStateChanged(auth, user => {
  window.myApp = new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})
