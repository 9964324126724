<template>
  <v-app>
    <v-app-bar
      app
      flat
      >
      <div class="d-none d-sm-flex pr-4">
        <!-- <v-img max-height="50" max-width="189" src="../assets/images/nanocrowdlogowhite1.png"></v-img> -->
        <div class="text-lg-h6">Nanocrowd</div>
      </div>
      <catalog-search></catalog-search>
      <v-switch
        flat
        v-model="dataMode"
        label="Data"
        class="pt-7 pl-4"
      ></v-switch>
      <v-btn 
        class="information-button" 
        icon 
        depressed 
        plain
        small
        @click="showDefinitions"
        >
        <v-icon>mdi-information-variant</v-icon>
      </v-btn>
      <nanocrowd-definitions></nanocrowd-definitions>
      <v-spacer></v-spacer>
      <v-switch
        flat
        v-model="darkMode"
        label="Dark"
        class="pt-7 pl-4"
      ></v-switch>
      <v-btn @click="onSignOut()" text class="ml-2 mt-1">
        <v-card-text>Sign Out</v-card-text>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs
          v-model="titleModel"
          color="grey darken-1"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(tab, index) in tabTitles" :key="index"
            @click="getTabData(tab.title)"
          >
            <v-icon class="pr-2">{{tab.icon}}</v-icon>
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-card flat>
      <span v-if="getDataMode === 'fancy'">
        <title-details v-if="hasData"></title-details>
      </span>
      <span v-if="getDataMode === 'brief'">
        <details-title-brief v-if="hasData"></details-title-brief>
      </span>
      <v-tabs-items v-model="titleModel" touchless>
        <v-tab-item v-for="(tab, index) in tabTitles" :key="index">
          <v-card class="mb-10" flat v-if="hasData">
            <span v-if="getDataMode === 'fancy'">
              <title-summary v-if="tab.title === 'Title Summary'"></title-summary>
              <most-like-titles v-if="tab.title === 'Most Like'"></most-like-titles>
              <nanogenre-titles v-if="tab.title === 'Nanogenre'"></nanogenre-titles>
              <minigenre-titles v-if="tab.title === 'Minigenre'"></minigenre-titles>
              <genre-titles v-if="tab.title === 'Genre'"></genre-titles>
            </span>
            <span v-if="getDataMode === 'brief'">
              <most-likes-title-brief v-if="tab.title === 'Most Like'"></most-likes-title-brief>
              <nanogenres-title-brief v-if="tab.title === 'Nanogenre'"></nanogenres-title-brief>
              <minigenres-title-brief v-if="tab.title === 'Minigenre'"></minigenres-title-brief>
              <genres-title-brief v-if="tab.title === 'Genre'"></genres-title-brief>
              <title-summary-brief v-if="tab.title === 'Title Summary'"></title-summary-brief>
            </span>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-app>
</template>

<script>
  import { getAuth, signOut } from 'firebase/auth'

  import CatalogSearch from './CatalogSearch'
  import MostLikeTitles from './MostLikeTitles'
  import TitleSummary from './TitleSummary'
  import NanogenreTitles from './NanogenreTitles'
  import MinigenreTitles from './MinigenreTitles'
  import GenreTitles from './GenreTitles'
  import TitleDetails from './TitleDetails'
  import DetailsTitleBrief from './TitleBrief/DetailsTitleBrief'
  import MostLikesTitleBrief from './TitleBrief/MostLikes/MostLikesTitleBrief'
  import NanogenresTitleBrief from './TitleBrief/Nanogenres/NanogenresTitleBrief'
  import MinigenresTitleBrief from './TitleBrief/Minigenres/MinigenresTitleBrief'
  import GenresTitleBrief from './TitleBrief/Genres/GenresTitleBrief'
  import TitleSummaryBrief from './TitleBrief/TitleSummaryBrief'
  import NanocrowdDefinitions from './NanocrowdDefinitions'

  export default {
    props: ['id'],
    components: {
      CatalogSearch,
      TitleSummary,
      MostLikeTitles,
      NanogenreTitles,
      MinigenreTitles,
      GenreTitles,
      TitleDetails,
      DetailsTitleBrief,
      MostLikesTitleBrief,
      NanogenresTitleBrief,
      MinigenresTitleBrief,
      GenresTitleBrief,
      TitleSummaryBrief,
      NanocrowdDefinitions,
    },
    data: () => ({
      tabTitles: [
        {title: 'Most Like', icon: 'mdi-approximately-equal-box'},
        {title: 'Nanogenre', icon: 'mdi-checkbox-blank-circle'},
        {title: 'Minigenre', icon: 'mdi-circle-medium'},
        {title: 'Genre', icon: 'mdi-circle-small'},
        {title: 'Title Summary', icon: 'mdi-graph'},
      ],
      titleModel: null,
      currentTab: '',
    }),
    computed: {
      hasData() {
        const title = this.$store.getters['title/getTitle']()
        return !!title
      },
      getDataMode() {
        const dataMode = this.$store.getters['dataMode/getDataMode']()
        if (dataMode === true) {
          return 'brief'
        } else {
          return 'fancy'
        }
      },  
      dataMode: {
        set(value) {
          if (value === true) {
            this.$store.dispatch('dataMode/setFancyDataMode')
          } else {
            this.$store.dispatch('dataMode/setBriefDataMode')
          }
        },
        get() {
          return this.$store.getters['dataMode/getDataMode']()
        }
      },
      darkMode: {
        set(value) {
          if (value === true) {
            localStorage.setItem('darkMode', 1)
            this.$vuetify.theme.dark = true
            this.$store.dispatch('darkMode/setDarkMode')
          } else {
            localStorage.removeItem('darkMode')
            this.$vuetify.theme.dark = false
            this.$store.dispatch('darkMode/setLightMode')
          }
        },
        get() {
          return this.$store.getters['darkMode/getDarkMode']()
        }
      }
    },
    methods: {
      // eslint-disable-next-line no-unused-vars
      getTabData(label) {},
      onSignOut() {
        const auth = getAuth()
        signOut(auth)
          .then(() => {
            this.$store.dispatch('auth/setAccessToken', null)
            this.$store.dispatch('auth/setEmailAddress')
            // this.$router.push('/login')
            this.$router.safePush('/login')
          })
          .catch((error) => {
            console.log(error)
          })
      },
      showDefinitions() {
        this.$store.dispatch('nanocrowdDefinitions/setIsActivated', true)
      }
    },
    watch: {
      async '$route.params.id'(newId) {
        await this.$store.dispatch('title/fetchTitle', newId)
      },
      titleModel(titleTab) {
        if (titleTab === 4) {
          this.currentTab = 'titleBrief'
        } else {
          this.currentTab = ''
        }
      },
    },
    async created() {
    },
    async mounted() {
      if (this.$route.params.id) {
        const localstorageTab = localStorage.getItem('appTab')
        if (localstorageTab) {
          switch(localstorageTab) {
            case 'mostlikes': 
              this.titleModel = 0
              break
            case 'nanogenres': 
              this.titleModel = 1
              break
            case 'minigenres':
              this.titleModel = 2
              break
            case 'genres':
              this.titleModel = 3
              break
            case 'summary':
              this.titleModel = 4
              break
          }
        }
        await this.$store.dispatch('title/fetchTitle', this.$route.params.id)
      }
    }
  }
</script>

<style> 
  main.v-main {
    padding: 100px 0px 0px !important;
  }

  .information-button {
    padding-top: 1px !important;
  }
</style>