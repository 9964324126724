<template>
<v-dialog
  fullscreen
  hide-overlay
  v-model="isActivated"
  min-width="375"
    >
    <v-card>
      <v-app-bar app>
        <v-card-title class="text-h5">
          Data Definitions
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="disableDialog"
        >
          Close
        </v-btn>
      </v-app-bar>

      <v-card>
        <v-card-text>{{ getTermsHeader }}</v-card-text>
        <v-data-table
          dense
          hide-default-footer
          :headers="termsHeaders"
          :items="getTerms"
          :items-per-page="-1"
          item-key="term"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ getTermsHeader }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>

      <v-card>
        <v-data-table
          dense
          hide-default-footer
          :headers="fileHeaders"
          :items="getTitleKeywords"
          :items-per-page="-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ getTitleKeywordsHeader }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>

      <v-card>
        <v-data-table
          dense
          hide-default-footer
          :headers="fileHeaders"
          :items="getMostLike"
          :items-per-page="-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ getMostLikeHeader }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>

      <v-card>
        <v-data-table
          dense
          hide-default-footer
          :headers="fileHeaders"
          :items="getGenre"
          :items-per-page="-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ getGenreHeader }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>

      <v-card>
        <v-data-table
          dense
          hide-default-footer
          :headers="fileHeaders"
          :items="getMinigenre"
          :items-per-page="-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ getMinigenreHeader }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>

      <v-card>
        <v-data-table
          dense
          hide-default-footer
          :headers="fileHeaders"
          :items="getNanogenre"
          :items-per-page="-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ getNanogenreHeader }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppFooter',
  data: () => ({
    expanded: [],
    singleExpand: true,
    termsHeaders: [
      { text: 'Term', sortable: false, value: 'term' },
      { text: 'Definition', sortable: false, value: 'definition' },
    ],
    fileHeaders: [
      { text: 'Field', sortable: false, value: 'field' },
      { text: 'Definition', sortable: false, value: 'definition' },
    ],
  }),
  methods: {
    disableDialog() {
      this.$store.dispatch('nanocrowdDefinitions/setIsActivated', false)
    }
  },
  computed: {
    isActivated() {
      return this.$store.getters['nanocrowdDefinitions/getIsActivated']()
    },
    getTerms() {
      const data = this.$store.getters['nanocrowdDefinitions/getTerms']()
      const terms = data.fields.map(item => {
        // console.log(item)
        const el = Object.values(item)
        return el.map(obj => {
          return {
              term: obj.label,
              definition: obj.definition,
            }
        })[0]
      })
      return terms
    },
    getTermsHeader() {
      const data = this.$store.getters['nanocrowdDefinitions/getTerms']()
      return data.header
    },
    getTitleKeywords() {
      const data = this.$store.getters['nanocrowdDefinitions/getTitleKeywords']()
      return data.fields
    },
    getTitleKeywordsHeader() {
      const data = this.$store.getters['nanocrowdDefinitions/getTitleKeywords']()
      return data.header
    },
    getMostLike() {
      const data = this.$store.getters['nanocrowdDefinitions/getMostLike']()
      return data.fields
    },
    getMostLikeHeader() {
      const data = this.$store.getters['nanocrowdDefinitions/getMostLike']()
      return data.header
    },
    getNanogenre() {
      const data = this.$store.getters['nanocrowdDefinitions/getNanogenre']()
      return data.fields
    },
    getNanogenreHeader() {
      const data = this.$store.getters['nanocrowdDefinitions/getNanogenre']()
      return data.header
    },
    getMinigenre() {
      const data = this.$store.getters['nanocrowdDefinitions/getMinigenre']()
      return data.fields
    },
    getMinigenreHeader() {
      const data = this.$store.getters['nanocrowdDefinitions/getMinigenre']()
      return data.header
    },
    getGenre() {
      const data = this.$store.getters['nanocrowdDefinitions/getGenre']()
      return data.fields
    },
    getGenreHeader() {
      const data = this.$store.getters['nanocrowdDefinitions/getGenre']()
      return data.header
    },
  }
}
</script>

<style scoped></style>
