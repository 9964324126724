const firebaseConfig = {
  apiKey: "AIzaSyDazfI0jPJEfA0stgayOO4L5C8r882ONzI",
  authDomain: "nanocrowd-clients.firebaseapp.com",
  projectId: "nanocrowd-clients",
  storageBucket: "nanocrowd-clients.appspot.com",
  messagingSenderId: "516945551822",
  appId: "1:516945551822:web:d2e3b8f8692c2845554b9f",
  measurementId: "G-7RW98ZG0J4"
};

export default firebaseConfig