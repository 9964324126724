<template>
  <mostlikes-data></mostlikes-data>
</template>

<script>
import MostlikesData from './MostLikesData'
export default {
  components: { MostlikesData }
}
</script>
<style scoped></style>
