import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    title: null,
  },
  getters: {
    getImdbId: (state) => () => {
      return state.title?.imdbId || null
    },
    getTitle: (state) => () => {
      return state.title
    },
    getActors: (state) => () => {
      return state.title?.actorNameList || []
    },
    getDirectors: (state) => () => {
      return state.title?.directorNameList || []
    },
    getTitleId: (state) => () => {
      return state.title?.ncTitleId || null 
    },
    getTitleName: (state) => () => {
      return state.title?.title || '' 
    },
    getYear: (state) => () => {
      return state.title?.year || '' 
    },
    getRating: (state) => () => {
      return state.title?.mpaa || '' 
    },
    getCrowdRating: (state) => () => {
      return state.title?.crowdRating ? parseFloat(state.title?.crowdRating).toFixed(1) : '' 
    },
    getCrowdSize: (state) => () => {
      return state.title?.crowdSize ? parseFloat(state.title.crowdSize).toFixed(1) : '' 
    },
    getTrailerId: (state) => () => {
      return state.title?.trailerId || null
    },
    getKeywords: (state) => () => {
      return state.title?.titleKeywords || []
    },
    getKeywordString: (state) => () => {
      return state.title.titleKeywords.map(item => item.keyword).join(' • ')
    },
    getMostLikes: (state) => () => {
      return state.title?.titleMostLikeTitles || null
    },
    getGenres: (state) => () => {
      return state.title?.titleGenreList || null
    },
    getGenre: (state) => (gId) => {
      if (state.title?.titleGenreList) {
        return state.title.titleGenreList.find(item => item.genreId === gId)
      }
      return null
    },
    getMinigenres: (state) => () => {
      return state.title?.titleMinigenreList || null
    },
    getMinigenre: (state) => (mgId) => {
      if (state.title?.titleMinigenreList) {
        return state.title.titleMinigenreList.find(item => item.minigenreId === mgId)
      }
      return null
    },
    getNanogenres: (state) => () => {
      return state.title?.titleNanogenreList || null
    },
    getNanogenre: (state) => (ngId) => {
      if (state.title?.titleNanogenreList) {
        return state.title.titleNanogenreList.find(item => item.nanogenreId === parseInt(ngId))
      }
      return null
    },
    getType: (state) => () => {
      if (state.title?.type) {
        switch (state.title.type?.toUpperCase()) {
          case '01-MOVIE':
          case '02-VIDEO':
          case '11-TVMOVIE':
          case 'TV-14SPECIAL':
          case '99-OTHER':
          case '21-SHORT':
            return 'Movie'
          case '03-DOCUMENTARY':
            return 'Documentary'
          case '12-TVMINI-SERIES':
          case '13-TVSERIES':
            return 'Series'
          default:
            return 'Movie'
        }
      } else {
        return 'Movie'
      }
    }
  },
  mutations: {
    SET_TITLE(state, data) {
      state.title = data
    },
  },
  actions: {
    async fetchTitle({ commit }, id) {
      let response = await dataApi.getTitle(id)
      if (response.data) {
        commit('SET_TITLE', response.data)
      }
    },
  },
}
