<template>
  <span>
    <v-card flat v-for="(genre, index) in getGenreTitles" :key="index" class="pb-3">
      <v-toolbar short dense flat>
        <v-card-text class="pb-0 pl-0 pr-0 d-sm-none" style="fontSize: x-small">{{genre.label}}</v-card-text>
        <v-card-title class="pb-0 d-none d-sm-block">{{genre.label}}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="onExpandAllClick(genre)"><v-icon>mdi-arrow-expand-all</v-icon></v-btn>
      </v-toolbar>
      <title-collection :items="genre.titleList"></title-collection>
      <v-divider class="mt-2"></v-divider>
    </v-card>
  </span>
</template>

<script>
import TitleCollection from './TitleLists/TitleCollection'
export default {
  props: [],
  components: {
    TitleCollection,
  },
  methods: {
    onExpandAllClick(genre) {
      localStorage.setItem('appTab', 'genres')
      localStorage.setItem('toolbarTitle', genre.label)
      // this.$router.push(`/bucket/${this.$store.getters['title/getTitleId']()}/genre/${genre.genreId}`)
      this.$router.safePush(`/bucket/${this.$store.getters['title/getTitleId']()}/genre/${genre.genreId}`)

    }
  },  
  computed: {
    getGenreLabels() {
      const genres = this.$store.getters['title/getGenres']()
      if (genres) {
        const labels = genres.map(item => item.label)
        return labels
      }
      return []
    },
    getGenreTitles() {
      const title = this.$store.getters['title/getGenres']()
      if (title) {
        const genreIdList = title.map(item => item.genreId)
        const labels = title.map(item => item.label)
        const genreTitleList = title.map(item => item.genreTitles).map(item => item)
        const result = [];
        for (let x = 0; x < labels.length; x++) {
          result.push({genreId: genreIdList[x], label: labels[x], titleList: genreTitleList[x]})
        }

        return result
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>