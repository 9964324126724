<template>
  <div>
    <app-toolbar id="login" title="Nanocrowd"></app-toolbar>
    <v-container fluid fill-height class="background">
      <v-dialog v-model="showSignIn" max-width="600px" persistent>
        <v-card outlined>
          <v-list-item three-line v-if="!resetSent">
            <v-list-item-content>
              <div class="text-overline mb-4 no-caps">Enter your email address and we'll send you a link to reset your password.</div>
              <v-list-item-title class="text-h5 mb-1">
                
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar tile size="80" color="grey">
              <v-icon large>mdi-account</v-icon>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item  v-if="!resetSent">
            <v-text-field
              label="Email*"
              v-model="email"
              required
            ></v-text-field>
          </v-list-item>

          <v-card-actions>
            <v-container>
              <v-row v-if="!resetSent">
                <v-col cols="6">
                  <v-btn outlined block rounded text @click="onCancel">
                    cancel
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn outlined block rounded text @click="onPasswordReset">
                    continue
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center">
                  <div class="text--primary">
                    <span v-if="!resetSent">Back to sign in?</span>
                    <span v-else>Password reset email sent.</span>
                    <v-btn
                      small
                      outlined
                      text
                      rounded
                      class="no-caps ml-4"
                      @click="onLogin"
                    >
                      Sign in
                    </v-btn>
                    <v-btn
                      v-if="resetSent"
                      small
                      outlined
                      text
                      rounded
                      class="no-caps ml-4"
                      @click="onCancel"
                    >
                      Cancel
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from '../components/AppFooter'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
export default {
  components: { AppFooter },
  data: () => ({
    email: '',
    error: null,
    resetSent: false,
  }),
  methods: {
    onPasswordReset() {
      this.error = null
      const auth = getAuth()

      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.resetSent = true
        })
        .catch((error) => {
          this.error = error.message
          this.resetSent = true
        })
    },
    onLogin() {
      this.$router.replace('/login')
    },
    onCancel() {
      this.$router.back()
    },
  },
  computed: {
    showSignIn: {
      get() {
        return true
      },
      set() {},
    },
    showError() {
      return !!this.error
    },
  },
  mounted() {
    this.resetSent = false
  },
}
</script>

<style scoped>
.no-caps {
  text-transform: unset !important;
}

.error-message-color input {
  color: '#00CFFF' !important;
}

.background {
  background: linear-gradient(
      to bottom,
      rgba(37, 20, 20, 0.52),
      rgba(6, 4, 4, 0.73)
    ),
    url(../assets/images/landing1920-1080.jpg);
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

</style>