import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../components/HomePage'
import BucketPage from '../components/BucketTitleList'
import UserLogin from '../components/UserLogin'
import PasswordReset from '../components/PasswordReset'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: { transition: 'none', requiresAuth: true },
  },
  {
    path: '/title/:id',
    name: 'TitlePage',
    component: HomePage,
    props: { default: true },
    meta: { transition: 'none', requiresAuth: true },
  },
  {
    path: '/bucket/:titleId/:bucket',
    name: 'MostLikeBucketPage',
    component: BucketPage,
    props: { default: true },
    meta: { transition: 'none', requiresAuth: true },
  },
  {
    path: '/bucket/:titleId/:bucket/:bucketId',
    name: 'BucketPage',
    component: BucketPage,
    props: { default: true },
    meta: { transition: 'none', requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: UserLogin,
    meta: { transition: 'zoom' },
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: { transition: 'zoom' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

router.safePush = function(route) {
  // Check if the target route is the same as the current route
  if (
    this.currentRoute.path === route ||
    (this.currentRoute.name === route.name &&
      JSON.stringify(this.currentRoute.params) === JSON.stringify(route.params) &&
      JSON.stringify(this.currentRoute.query) === JSON.stringify(route.query))
  ) {
    // Resolve the promise to avoid the NavigationDuplicated error
    return Promise.resolve(this.currentRoute)
  } else {
    // If the routes are different, proceed with the navigation
    return this.push(route)
  }
}

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  await store.dispatch('auth/setAuth')
  const auth = store.getters['auth/getAuth']()
  const currentUser = auth?.currentUser

  if (requiresAuth && !currentUser) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  }
  else { 
    next()
  }
})

export default router
