export default {
  namespaced: true,
  state: {
    darkMode: false,
  },
  getters: {
    getDarkMode: (state) => () => {
      return state.darkMode
    },
  },
  mutations: {
    SET_DARK_MODE(state, mode) {      
      state.darkMode = mode
    },
  },
  actions: {
    async setDarkMode({ commit }) {
    commit('SET_DARK_MODE', true)
    },
    async setLightMode({ commit }) {
      commit('SET_DARK_MODE', false)
    },
  },
}
