<template>
  <v-lazy transition="fade-transition">
    <vue-horizontal-list v-if="hasItems" :items="getItems" :options="options">
      <template v-slot:default="{ item }">
        <title-card :item="item"></title-card>
      </template>
    </vue-horizontal-list>
  </v-lazy>
</template>

<script>
import VueHorizontalList from 'vue-horizontal-list'
import TitleCard from './TitleCards/TitleCard.vue'

export default {
  components: { VueHorizontalList, TitleCard },
  name: 'TitleHorizontalList',
  props: ['items'],
  data: () => ({
    options: {
      item: {
        // css class to inject into each individual item
        class: 'nc-item',
        // padding between each item
        padding: 8,
      },
      // responsive: [
      //   { end: 480, size: 2 },
      //   { start: 481, end: 768, size: 3 },
      //   { start: 769, end: 1024, size: 4 },
      //   { start: 1025, end: 1400, size: 6 },
      //   { size: 8 },
      // ],
      responsive: [
        { end: 480, size: 6 },
        { start: 481, end: 768, size: 8 },
        { start: 769, end: 1024, size: 10 },
        { start: 1025, end: 1400, size: 12 },
        { size: 16 },
      ],
      navigation: {
        // when to show navigation
        start: 200,
      },
      position: {
        // Start from '1' on mounted.
        // start: 1,
      },
      list: {
        // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
        // windowed: 1200,
        // Because: #app {padding: 80px 24px;}
        // padding: 24,
      },
    },
  }),
  methods: {},
  computed: {
    hasItems() {
      return !!this.items.length
    },
    getItems() {
      return this.items
    },
  },
  mounted() {
  },
}
</script>
<style scoped>

body {
  margin: 0;
  padding: 0;
}
</style>
