<template>
  <v-data-table
    dense
    show-expand
    :headers="headers"
    :items="genres"
    :items-per-page="-1"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    :footer-props="{
      'items-per-page-options': [1, 2, 3, 5, -1]
    }"
    item-key="genreId"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Genre</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          v-model="singleExpand"
          label="Single expand"
          class="mt-2"
        ></v-switch>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length">
        <table class="genre-titles">
          <thead>
            <tr>
              <th class="pl-2 text-start">IMDb Id</th>
              <th class="pl-2 text-start">Title</th>
              <th class="pl-2 text-start">Year</th>
              <th class="pl-2 text-start">Type</th>
              <th class="pl-2 text-start">Crowd Rating</th>
              <th class="pl-2 text-start">Crowd Size</th>
              <th class="pl-2 text-start">Distance</th>
            </tr>
          </thead>
          <tr v-for="el in item.genreTitles" :key="el.imdbId">
            <td>
              <a
                target="_blank"
                :href="el.imdbLink"
                style="text-decoration: none"
                >{{ el.imdbId }}</a
              >
            </td>
            <td>
              <div @click="onTitleClicked(el.ncTitleId)" class="hand-cursor">
                {{ el.title }}
              </div>
            </td>
            <td>{{ el.year }}</td>
            <td>{{ el.type }}</td>
            <td>{{ el.crowdRating }}</td>
            <td>{{ el.crowdSize }}</td>
            <td>{{ el.distance.toFixed(1) }}</td>
          </tr>
        </table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'GenresTitleBrief',
  data: () => ({
    expanded: [],
    singleExpand: true,
    headers: [
      {
        text: 'Score',
        sortable: false,
        align: 'start',
        value: 'score'
      },
      { text: 'ggID', sortable: false, value: 'genreId' },
      { text: 'Name', sortable: false, value: 'genreName' },
      { text: 'Genre Title keywords', sortable: false, value: 'genreKeywords' },
      { text: '', value: 'data-table-expand' }
    ]
  }),
  methods: {
    onTitleClicked(ncTitleId) {
      // this.$router.push(`/title/${ncTitleId}`)
      this.$router.safePush(`/title/${ncTitleId}`)
    }
  },
  computed: {
    genres() {
      const titleGenres = this.$store.getters['title/getGenres']()
      if (titleGenres) {
        const genreList = titleGenres.map((item) => {
          item.genreTitles.map((el) => {
            el.imdbLink = `https://www.imdb.com/title/${el.imdbId}`
          })

          return {
            genreName: item.label,
            score: item.distance,
            genreId: item.genreId,
            genreKeywords: item.genreKeywords.map((el) => el.keyword).join(' • '),
            genreTitles: item.genreTitles
          }
        })

        // go through each of the titles in the item.genreTitles and build the
        return genreList
      } else {
        return []
      }
    }
  }
}
</script>
<style scoped>
.genre-titles {
  position: relative;
  margin: 10px auto;
  padding: 0;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  text-align: start;
}

.genre-titles tr {
  margin: 0;
  padding: 0;
  border: 0;
  border: 1px solid #999;
  width: 100%;
}

.genre-titles tr td {
  margin: 0;
  padding: 4px 8px;
  border: 0;
  border: 1px solid #999;
}

.hand-cursor {
  cursor: pointer;
  color: #1976d2;
}
</style>
