<template>
  <v-container fluid>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">titleID</th>
            <th class="text-left">ttID</th>
            <th class="text-left">Title</th>
            <th class="text-left">Year</th>
            <th class="text-left">Type</th>
            <th class="text-left">Crowd size</th>
            <th class="text-left">Crowd rating</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left">{{ getTitleId }}</td>
            <td class="text-left">
              <a target="_blank" :href="getImdbLink" style="text-decoration: none">{{
                getImdbId
              }}</a>
            </td>
            <td class="text-left">{{ getTitleName }}</td>
            <td class="text-left">{{ getYear }}</td>
            <td class="text-left">{{ getType }}</td>
            <td class="text-left">{{ getCrowdSize }}</td>
            <td class="text-left">{{ getCrowdRating }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-data-table
      dense
      show-expand
      hide-default-footer
      :headers="header"
      :items="getKeywords"
      item-key="label2"
    >
      <template v-slot:expanded-item="{ item }">
        <td colspan="50%">
          <table class="title-keywords">
            <thead>
              <tr>
                <th class="text-start pl-2">Keyword</th>
                <th class="text-start pl-2">Weight</th>
              </tr>
            </thead>
            <tr v-for="el in item.list" :key="el.keyword">
              <td>{{ el.keyword }}</td>
              <td>{{ el.weight.toFixed(1)}}</td>
            </tr>
          </table>
        </td>
      </template>
    </v-data-table>
    <credits-title-brief class="pt-2"></credits-title-brief>
  </v-container>
</template>
<script>
  import CreditsTitleBrief from './CreditsTitleBrief'
  export default {
    components: {
      CreditsTitleBrief,
    },
    data: () => ({
      title: null,
      header: [{
        text: 'Title keywords',
        align: 'left',
        sortable: false,
        value: 'label',
      }, { 
        text: '', value: 'data-table-expand' 
      }],
      singleExpand: false,
      expanded: [],
      headerWidth: 200
    }),
    computed: {
      getTitleName() {
        return this.$store.getters['title/getTitleName']()
      },
      getYear() {
        return this.$store.getters['title/getYear']()
      },
      getType() {
        return this.$store.getters['title/getType']()
      },  
      getCrowdSize() {
        return this.$store.getters['title/getCrowdSize']()
      },
      getCrowdRating() {
        return this.$store.getters['title/getCrowdRating']()
      },
      getTitleId() {
        return this.$store.getters['title/getTitleId']()
      },
      getImdbId() {
        return this.$store.getters['title/getImdbId']()
      },
      getImdbLink() {
        const imdbId = this.$store.getters['title/getImdbId']()
        return `https://www.imdb.com/title/${imdbId}`
      },
      getTitleWordHeader() {
        const keywordString = this.$store.getters['title/getKeywordString']()
        const titleWordHeader = {
          text: keywordString,
          align: 'start',
          sortable: false,
          value: 'words'
        }
        return [titleWordHeader]
      },
      getKeywords() {
        const label = this.$store.getters['title/getKeywordString']()
        const list = this.$store.getters['title/getKeywords']()
        return [{
          label,
          list
        }]
      }
    },
    async mounted() {
    }
  }
</script>
<style scoped>
.title-keywords {
  position: relative;
  margin: 10px auto;
  padding: 0;
  width: 100%;
  height: auto;
  border-collapse: collapse;
  text-align: left;
}

.title-keywords tr {
  margin: 0;
  padding: 0;
  border: 0;
  border: 1px solid #999;
  width: 100%;
}

.title-keywords tr td {
  margin: 0;
  padding: 4px 8px;
  border: 0;
  border: 1px solid #999;
}
</style>