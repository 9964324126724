<template>
  <genres-data></genres-data>
</template>

<script>
import GenresData from './GenresData';
export default {
  components: {
    GenresData
  },
  name: 'GenresTitleBrief',
  data: () => ({}),
  methods: {},
  computed: {}
}
</script>
<style scoped></style>
