<template>
  <minigenres-data></minigenres-data>
</template>

<script>
import MinigenresData from './MinigenresData'
export default {
  components: { MinigenresData },
  data: () => ({}),
  methods: {},
  computed: {}
}
</script>
<style scoped></style>
