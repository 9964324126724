<template>
  <span>
    <v-card v-for="(nanogenre, index) in getNanogenreTitles" :key="index" class="pb-3">
      <span :id="nanogenre.label">
        <v-toolbar short dense flat>
          <v-card-subtitle class="pb-0 d-sm-none">{{nanogenre.label}}</v-card-subtitle>
          <v-card-title class="pb-0 d-none d-sm-block">{{nanogenre.label}}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn @click="onExpandAllClick(nanogenre)" class="pt-2"><v-icon>mdi-arrow-expand-all</v-icon></v-btn>
        </v-toolbar>
      </span>
      <title-collection :items="nanogenre.titleList"></title-collection>
    </v-card>
  </span>
</template>

<script>
import TitleCollection from './TitleLists/TitleCollection'
export default {
  props: [],
  components: {
    TitleCollection,
  },
  data: () => ({}),
  methods: {
    onExpandAllClick(nanogenre) {
      localStorage.setItem('appTab', 'nanogenres')
      localStorage.setItem('toolbarTitle', nanogenre.label)
      this.$router.safePush(`/bucket/${this.$store.getters['title/getTitleId']()}/nanogenre/${nanogenre.nanogenreId}`)
    }
  },  
  computed: {
    getNanogenreLabels() {
      const nanogenres = this.$store.getters['title/getNanogenres']()
      if (nanogenres) {
        const labels = nanogenres.map(item => item.nanogenreWordList.join(' • '))
        return labels
      }
      return []
    },
    getNanogenreTitles() {
      const title = this.$store.getters['title/getNanogenres']()
      if (title) {
        const nanogenreIdList = title.map(item => item.nanogenreId)
        const labels = title.map(item => item.nanogenreWordList[0].join(' • '))

        const nanogenreTitleList = title.map(item => item.nanogenreTitles).map(item => item)
        const result = [];
        for (let x = 0; x < labels.length; x++) {
          result.push( { nanogenreId: nanogenreIdList[x], label: labels[x], titleList: nanogenreTitleList[x] } )
        }

        return result
      }
      return []
    },
  }
}
</script>

<style scoped>

</style>