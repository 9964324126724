<template>
  <title-horizontal-list :items="items"></title-horizontal-list>
</template>

<script>
import TitleHorizontalList from './TitleHorizontalList'
export default {
  components: {
    TitleHorizontalList,
  },

  name: 'TitleCollection',
  props: ['items'],
  data: () => ({}),
  computed: {},
  mounted() {},
}
</script>

<style scoped></style>
