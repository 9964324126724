<template>
  <v-container fluid>
    <v-row>
      <v-card flat>
        <v-card-title style="word-break: break-word">{{getName}} ({{getYear}})</v-card-title>
      </v-card>
    </v-row>
    <v-row>
      <div>
        <div class="d-flex flex-column mb-6">
          <v-card class="pl-4" flat>
            <title-card :height="185" :width="130" :item="getTitle" class="pt-0"></title-card>
          </v-card>
        </div>
      </div>
      <div>
        <div class="d-flex flex-column ml-0" v-if="!isSeries" >
          <v-card class="pl-1 pt-2 pr-2 pb-2" flat>
            <a :href="getLetterboxdLink" target="_blank">
              <v-img
                max-width="53"
                alt="" 
                src="../assets/images/wide110logo_letterboxd.png"></v-img>
            </a>
          </v-card>
        </div>
        <div class="d-flex flex-column">
          <v-card class="pl-1 pt-2 pr-2 pb-2" flat>
            <a :href="getImdbLink" target="_blank">
              <v-img
                max-width="50"
                alt="" 
                src="../assets/images/wide110logo_imdb.png"></v-img>
            </a>
          </v-card>
        </div>
        <div class="d-flex flex-column">
          <v-card class="pl-1 pt-2 pr-2 pb-2" flat>
            <a :href="getAmazonLink" target="_blank">
              <v-img
                max-width="50"
                alt="" 
                src="../assets/images/wide110logowh_amazon.png"></v-img>
            </a>
          </v-card>
        </div>
        <div class="d-flex flex-column" v-if="getTrailerId">
          <v-card class="pl-1 pt-2 pr-2 pb-2" flat>
            <title-trailer></title-trailer>
          </v-card>
        </div>
      </div>
      <div>
        <v-card class="d-flex flex-column" flat>
          <v-card flat>
            <v-card-text class="pt-1 pb-0"><span class="font-weight-bold">{{getType}} </span>- MPAA: {{getRating}}</v-card-text>
          </v-card>
        </v-card>
        <v-card class="d-flex" flat>
          <v-card flat>
            <v-card-text class="pt-0 pb-1 font-italic"><span class="font-weight-bold">Crowd Rating: </span>{{getCrowdRating}} out of 5</v-card-text>
          </v-card>
          <v-card flat>
            <v-card-text class="pt-0 pb-1 font-italic"><span class="font-weight-bold">Crowd Size: </span>{{getCrowdSize}} out of 5</v-card-text>
          </v-card>
        </v-card>
        <div class="d-flex flex-column">
          <title-credits></title-credits>
        </div>
      </div>
      <div>
        <div class="d-flex flex-column">
          <v-card class="pb-3 pt-0" flat>
            <tag-cloud :keywords="getKeywords"></tag-cloud>
          </v-card>
        </div>
      </div>
    </v-row>
  </v-container>
</template>
<script>
  import TitleCard from './TitleLists/TitleCards/TitleCard'
  import TagCloud from './TagCloud'
  import TitleCredits from './TitleCredits'
  import TitleTrailer from './TitleTrailer'
  export default {
    components: {
      TitleCard,
      TagCloud,
      TitleCredits,
      TitleTrailer,
    },
    data: () => ({
      title: null
    }),
    computed: {
      getTitle() {
        return this.$store.getters['title/getTitle']()
      },
      getTitleName() {
        return this.$store.getters['title/getTitleName']()
      },
      getKeywords() {
        return this.$store.getters['title/getKeywords']()
      },
      getTitleId() {
        return this.$store.getters['title/getTitleId']()
      },
      getType() {
        return this.$store.getters['title/getType']()
      },
      getName() {
        return this.$store.getters['title/getTitleName']()
      },
      getYear() {
        return this.$store.getters['title/getYear']()
      },
      getRating() {
        return this.$store.getters['title/getRating']()
      },
      getCrowdRating() {
        return this.$store.getters['title/getCrowdRating']()
      },
      getCrowdSize() {
        return this.$store.getters['title/getCrowdSize']()
      },
      getTrailerId() {
        return !!this.$store.getters['title/getTrailerId']()
      },
      isSeries() {
        const type = this.$store.getters['title/getType']()
        return type === 'Series'
      },
      getLetterboxdLink() {
        const imdbId = this.$store.getters['title/getImdbId']()
        const url = `https://letterboxd.com/imdb/${imdbId}`
        return url
      },
      getImdbLink() {
        const imdbId = this.$store.getters['title/getImdbId']()
        const url = `https://imdb.com/title/${imdbId}`
        return url
      },
      getAmazonLink() {
        const title = this.$store.getters['title/getTitleName']()
        const url = `https://www.amazon.com/s?k=${title.replaceAll(
          ' ',
          '+'
        )}&i=movies-tv&camp=1789&creative=9325&linkCode=ur2&tag=nanocrowd00-20`

        return url
      },
    },
    async mounted() {
      // this.title = this.$store.getters['title/getTitle']()
    }
  }
</script>