<template>
  <v-container fluid class="pa-0">
    <div class="item  no-background-hover cursor" v-if="hasData" @click="onTitleDetails(item.ncTitleId)">
      <v-lazy transition="fade-transition">
        <v-img
          :src="`https://nccdn.sfo3.cdn.digitaloceanspaces.com/images/medium/${item.ncTitleId}.jpg`"
          :max-height="`${height}`"
          :max-width="`${width}`"
          class="rounded"
        ></v-img>
      </v-lazy>
    </div>
  </v-container>
</template>

<script>
// import TitleRating from './TitleRating'

export default {
  props: {item: Object, height: Number, width: Number},
  // components: { TitleRating },
  data: () => ({}),
  methods: {
    onTitleDetails(ncTitleId) {
      // this.$router.push(`/title/${ncTitleId}`)
      this.$router.safePush(`/title/${ncTitleId}`)
    },
  },
  computed: {
    hasData() {
      return !!this.item?.ncTitleId
    },
    getClass() {
      return 'fullOpacity'
    },
  },
  mounted() {},
}
</script>

<style scoped>
.item {
  /* padding: 16px 16px; */
  border-radius: 3px;
  /* background: #444040; */
}
.fullOpacity {
  opacity: 1;
}

.no-background-hover::before {
  background-color: transparent !important;
}

.cursor {
  cursor: pointer;
}
</style>
