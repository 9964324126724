import { getAuth } from 'firebase/auth'

export default {
  namespaced: true,
  state: {
    auth: null,
    accessToken: null,
    emailAddress: null,
  },
  getters: {
    getAuth: (state) => () => {
      return state.auth
    },
    getEmailAddress: (state) => () => {
      const auth = getAuth()
      if (auth && auth.currentUser) {
        state.emailAddress = auth.currentUser.email
      } else {
        state.emailAddress = null
      }
      return state.emailAddress
    },
  },
  mutations: {    
    SET_AUTH(state, data) {
      state.auth = data
    },
    SET_ACCESS_TOKEN(state, data) {
      state.accessToken = data
      data ? localStorage.setItem('accessToken', data) : localStorage.removeItem('accessToken')
    },
    SET_EMAIL_ADDRESS(state, data) {
      state.emailAddress = data
      data ? localStorage.setItem('emailAddress', data) : localStorage.removeItem('emailAddress')
    },
  },
  actions: {
    setAuth( { commit }) {
      const auth = getAuth()
      commit('SET_AUTH', auth)
    },
    setAccessToken({ commit }, accessToken) {
      commit('SET_ACCESS_TOKEN', accessToken)
    },
    setEmailAddress({ commit }, emailAddress) {
      commit('SET_EMAIL_ADDRESS', emailAddress)
    },
  },
}
