<template>
  <span>
    <v-card flat>
      <v-toolbar dense flat>
        <v-card-title class="pb-0">Most Like Titles</v-card-title>
        <v-spacer></v-spacer>
        <v-btn @click="onExpandAllClick()"><v-icon>mdi-arrow-expand-all</v-icon></v-btn>
      </v-toolbar>
    </v-card>
    <title-collection :items="getMostLikeTitles"></title-collection>
    <v-divider class="mt-4"></v-divider>
  </span>
</template>

<script>
import TitleCollection from './TitleLists/TitleCollection'

export default {
  props: [],
  components: {
    TitleCollection
  },  
  methods: {
    onExpandAllClick() {
      localStorage.setItem('appTab', 'mostlikes')
      localStorage.setItem('toolbarTitle', 'Most Like Titles')
      // this.$router.push(`/bucket/${this.$store.getters['title/getTitleId']()}/mostlikes`)
      this.$router.safePush(`/bucket/${this.$store.getters['title/getTitleId']()}/mostlikes`)
    }
  },  
  computed: {
    getMostLikeTitles() {
      const mostLikes = this.$store.getters['title/getMostLikes']()
      if (mostLikes) {
        const titles = mostLikes.map((item) => {
          return { 
            ncTitleId: item.ncTitleId, 
            title: item.title, 
            year: item.year,
            crowdRating: item.crowdRating,
            crowdSize: item.crowdSize,
          }
        })
        return titles
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>