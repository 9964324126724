import dataApi from '../../services/dataApi'

export default {
  namespaced: true,
  state: {
    isActivated: false,
    dataDefinitions: null,
  },
  getters: {
    getIsActivated: (state) => () => {
      return state.isActivated
    },
    getTerms: (state) => () => {
      return state.dataDefinitions.terms || null
    },
    getTitleKeywords: (state) => () => {
      return state.dataDefinitions.titleKeywords || null
    },
    getMostLike: (state) => () => {
      return state.dataDefinitions.mostlike || null
    },
    getNanogenre: (state) => () => {
      return state.dataDefinitions.nanogenre|| null
    },
    getMinigenre: (state) => () => {
      return state.dataDefinitions.minigenre || null
    },
    getGenre: (state) => () => {
      return state.dataDefinitions.genre || null
    },
  },
  mutations: {
    SET_IS_ACTIVATED(state, value) {      
      state.isActivated = value
    },
    SET_DATA_DEFINITIONS(state, data) {
      state.dataDefinitions = data
    }
  },
  actions: {
    async setIsActivated({ commit }, value) {
      commit('SET_IS_ACTIVATED', value)
    },
    async getDataDefinitions({ commit }) {
      let response = await dataApi.getDataDefinitions()
      if (response.data) {
        commit('SET_DATA_DEFINITIONS', response.data)
      }
    }
  },
}
