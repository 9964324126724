<template>
  <div fluid class="pa-0" >
    <v-card flat >
      <v-simple-table >
        <template v-slot:default>
          <tbody>
            <tr>
              <td width="140" class="text-left">
                <v-card-text style="text-decoration: none">{{ getActors[0] }}</v-card-text>
              </td>
              <td class="text-left">
                <v-card-text style="text-decoration: none">{{ getActors[1] }}</v-card-text>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <!-- <v-divider></v-divider> -->
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td width="140" class="text-left">
                <v-card-subtitle style="text-decoration: none">{{ getDirectors[0] }}</v-card-subtitle>
              </td>
              <td class="text-left">
                <v-card-text style="text-decoration: none">{{ getDirectors[1] }}</v-card-text>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
  export default {
    components: {
    },
    data: () => ({
      title: null,
    }),
    computed: {
      getActors() {
        const actors = this.$store.getters['title/getActors']()
        if (actors) {
          const actorList = ['Cast', actors.map(item => item.name).join(' • ')]
          return actorList
        } else {
          return []
        }
      },
      getDirectors() {
        const directors = this.$store.getters['title/getDirectors']()
        if (directors) {
          return ['Directed by', directors.map(item => item.name).join(' • ')]
        } else {
          return []
        }
      }
    },
    async mounted() {
    }
  }
</script>
<style scoped>
.text-left {
  padding-left: 0px !important
}
</style>