<template>
  <v-container fluid>
    <span>
      <v-card flat>
        <v-card-title class="pl-0 pt-0 pb-0" >Actors</v-card-title>
        <v-card-text class="pl-0 pb-0 pt-0">{{getActors}}</v-card-text>
      </v-card>
    </span>
    <span>
      <v-card flat>
        <v-card-title class="pl-0 pt-0 pb-0" >Directed By</v-card-title>
        <v-card-text class="pl-0 pb-0 pt-0">{{getDirectors}}</v-card-text>
      </v-card>
    </span>
  </v-container>
</template>
<script>
  export default {
    props: [],
    data: () => ({
    }),
    methods: {
    },
    computed: {
      getActors() {
        const actors = this.$store.getters['title/getActors']()
        const result = actors.map(item => item.name).join(', ')
        return result
      },
      getDirectors() {
        const directors = this.$store.getters['title/getDirectors']()
        const result = directors.map(item => item.name).join(', ')
        return result
      }
    }
  }
</script>
<style scoped>

</style>